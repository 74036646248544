import React, { Component } from "react";
import "./index.css";
import styled from "styled-components";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import ContactForm from "./Components/ContactForm";
import Delighters from "./Components/ReactDelighters";
import { Helmet } from "react-helmet";
import { setWindowObj, isDesktop, isPhone } from "./Utils/WindowUtil.js";
import { connect } from "react-redux";
import ActionCreator from "./ActionCreator";

import LanguageSettingIcon from "./Components/LanguageSettingIcon";

import ProjectPageData from "../content/project-data";
import LabsData from "../content/labs-data";

// const navbarWidth = 60;
const navbarHeight = 60;
const footerHeight = 60;
const mobileFooterHeight = 150;

const _labsData = LabsData.filter(l => !!l[3]).map(x => x[3]);

const SPECIAL_SEO_DATA = _labsData.reduce((acc, p) => {
  acc[p.id] = {
    title: p.title + " | RevLabs | RevtelTech 忻旅科技股份有限公司",
    description: p.description,
    image: p.images[0],
  };

  return acc;
}, {});

const SPECIAL_SEO_DATA2 = ProjectPageData.reduce((acc, p) => {
  if (p.detail) {
    acc[p.detail.id] = {
      title: p.detail.title + " | 開發成果 | RevtelTech 忻旅科技股份有限公司",
      description: p.detail.description,
      image: p.detail.images[0],
      keywords: p.title_tw,
    };
  }
  return acc;
}, {});

const SEO_DATA = {
  general: {
    title: "RevtelTech 忻旅科技股份有限公司",
    description:
      "軟體顧問(Consult)/系統開發(Development)/營運協力(Operation) | 網站/APP/物聯網/區塊鏈 | 過往合作遍及電商、物流、醫療、金融、展覽、印刷及文創等多個領域，協作超過 80 個以上合作方案，提供軟體產品全生命週期所需的一站式服務。",
    image: "/images/revtel-og-2.png",
    keywords:
      "軟體顧問,軟體開發,網站開發,web,APP,區塊鏈,blockchain,IoT,物聯網,Web3,NFT,NFT,數位轉型,數位升級,ReactJS,React Native,AWS,AWS Lambda,加密鏈科技,Inigma,奕果雲端數位,Eculture,好好選,HoHoKing",
  },
  about: {
    title: "關於忻旅 | RevtelTech 忻旅科技股份有限公司",
    description: "透過專業技術協作打通線上線下最後一哩路",
    image: "/images/revtel-og-2.png",
    keywords:
      "軟體顧問,軟體開發,網站開發,web,APP,區塊鏈,blockchain,IoT,物聯網,Web3,NFT,NFT,數位轉型,數位升級,ReactJS,React Native,AWS,AWS Lambda,加密鏈科技,Inigma,奕果雲端數位,Eculture,好好選,HoHoKing",
  },
  concepts: {
    title: "觀點分享 | RevtelTech 忻旅科技股份有限公司",
    description:
      "來自實踐所累積的經驗觀點，從分享中學習成長。更多資訊可參考 https://medium.com/revtel-tech",
    image: "/images/revtel-og-2.png",
    keywords:
      "軟體顧問,軟體開發,網站開發,web,APP,區塊鏈,blockchain,IoT,物聯網,Web3,NFT,NFT,數位轉型,數位升級,ReactJS,React Native,AWS,AWS Lambda,加密鏈科技,Inigma,奕果雲端數位,Eculture,好好選,HoHoKing",
  },
  history: {
    title: "過往經歷 | RevtelTech 忻旅科技股份有限公司",
    description:
      "研發成果/歷史軌跡/開源技術/講座分享。積極參與及舉辦各式活動論壇，相信一步一腳印的紮實前行定能得到更大的收穫及肯定。",
    image: "/images/revtel-og-2.png",
    keywords:
      "軟體顧問,軟體開發,網站開發,web,APP,區塊鏈,blockchain,IoT,物聯網,Web3,NFT,NFT,數位轉型,數位升級,ReactJS,React Native,AWS,AWS Lambda,加密鏈科技,Inigma,奕果雲端數位,Eculture,好好選,HoHoKing",
  },
  project: {
    title: "開發成果 | RevtelTech 忻旅科技股份有限公司",
    description:
      "客製化電商/物聯網/區塊鏈/新創產品/內部系統/品牌概念/雲端服務/顧問諮詢。過往提供超過八十個軟體方案，橫跨至少十領域。",
    image: "/images/revtel-og-2.png",
    keywords:
      "軟體顧問,軟體開發,網站開發,web,APP,區塊鏈,blockchain,IoT,物聯網,Web3,NFT,NFT,數位轉型,數位升級,ReactJS,React Native,AWS,AWS Lambda,加密鏈科技,Inigma,奕果雲端數位,Eculture,好好選,HoHoKing",
  },
  service: {
    title: "服務概覽 | RevtelTech 忻旅科技股份有限公司",
    description:
      "諮詢規劃/開發執行/營運協力。提供完整服務，從初始顧問諮詢、中期實際開發到上線後營運規劃都是我們的協助範圍。ReactJS / React Native / Python / Node.js / AWS / Embedded System / Linux Programing / NFC / Bluetooth / BLE / Cold Wallet / Smart Contract",
    image: "/images/revtel-og-2.png",
    keywords:
      "軟體顧問,軟體開發,網站開發,web,APP,區塊鏈,blockchain,IoT,物聯網,Web3,NFT,NFT,數位轉型,數位升級,ReactJS,React Native,AWS,AWS Lambda,加密鏈科技,Inigma,奕果雲端數位,Eculture,好好選,HoHoKing",
  },
  labs: {
    title: "RevLabs | RevtelTech 忻旅科技股份有限公司",
    description:
      "各種研發產出及自發項目。BakerShell 烘焙 line 電商 / NFT 圖像合成器 / ReactConf.tv 影音管理 / react-native-nfc-manager / NFC 控制 APP / 印刷模數計算器",
    image: "/images/revtel-og-2.png",
    keywords:
      "軟體顧問,軟體開發,網站開發,web,APP,區塊鏈,blockchain,IoT,物聯網,Web3,NFT,NFT,數位轉型,數位升級,ReactJS,React Native,AWS,AWS Lambda,加密鏈科技,Inigma,奕果雲端數位,Eculture,好好選,HoHoKing",
  },
};

class Layout extends Component {
  state = {
    isPhone: isPhone(),
    lang: "tw",
  };

  componentDidMount() {
    window.addEventListener("scroll", this._onScroll);
    setWindowObj(window);
    this.setState({ isPhone: isPhone() });

    this.props.appActions.setLang(
      this.props.location.pathname.indexOf("/en") !== -1 ? "en" : "tw"
    );

    this.setState({
      lang: this.props.location.pathname.indexOf("/en") !== -1 ? "en" : "tw",
    });

    // add adsense
    try {
      const script = document.createElement("script");
      script.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
      script.async = true;
      script["data-ad-client"] = "ca-pub-9251535461070067";
      document.body.appendChild(script);
    } catch (e) {
      console.warn("append adsense fail", e);
    }

    // ************************************ //
    const currentDomain = window.location.hostname;
    const targetDomain = "www.revtel.tech";

    // 檢查是否為本地開發環境 //
    const isLocalhost =
      currentDomain === "localhost" ||
      currentDomain === "127.0.0.1" ||
      currentDomain.includes(".local");

    // 如果不是本地開發環境且網域不符合目標網域 //
    if (!isLocalhost && currentDomain !== targetDomain) {
      // 建立新的 URL
      const newUrl = new URL(window.location.href);
      newUrl.hostname = targetDomain;

      // 執行轉跳
      window.location.href = newUrl.toString();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this._onScroll);
  }

  render() {
    let {
      location: { pathname, href },
      pageContext: { keywords },
    } = this.props;
    let passBreakpoint = !!this.passBreakpoint;
    pathname = this._normalizePath(pathname);

    let withPaddingTop = true;
    let showFooter = true;
    let showNavbar = true;

    if (pathname === "/") {
      withPaddingTop = false;
    } else if (
      pathname === "/branding/" ||
      pathname.indexOf("/branding/") === 0
    ) {
      showFooter = false;
      showNavbar = false;
    }

    let seoData = SEO_DATA["general"];
    const has = (t, s) => t.toUpperCase().indexOf(s.toUpperCase()) !== -1;
    const pHas = s => has(pathname, s);

    seoData = SEO_DATA["general"];

    const allSEOData = {
      ...SEO_DATA,
      ...SPECIAL_SEO_DATA,
      ...SPECIAL_SEO_DATA2,
    };
    Object.keys(allSEOData).map(k => {
      if (pHas(k)) {
        seoData = allSEOData[k];
      }
    });

    return (
      <Delighters>
        <Wrapper withPaddingTop={withPaddingTop}>
          <Helmet>
            <title>{seoData.title}</title>
            <meta name="description" content={seoData.description} />
            <meta
              name="google-site-verification"
              content="VgMfxef8huYCYeEyLbKCsyulVnHtsmXRNShI30tvOnU"
            />
            <meta
              name="msvalidate.01"
              content="388EB2B3A57D99D97284319E6B71CA18"
            />
            <meta name="image" content="/images/og-image.png" />
            <meta property="og:url" content={href} />
            <meta property="og:title" content={seoData.title} />
            <meta
              name="facebook-domain-verification"
              content="gjatokfbr72a3crc02prhnzf2r1eyp"
            />
            <meta
              name="facebook-domain-verification"
              content="97n308kxq9inq9pgf8nzsim6eq90m1"
            />
            <meta name="og:description" content={seoData.description} />

            <meta property="og:type" content="website" />
            <meta property="og:image" content={seoData.image} />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="3063" />
            <meta property="og:image:height" content="3375" />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:url" content={href} />
            <meta name="twitter:title" content={seoData.title} />
            <meta name="twitter:description" content={seoData.description} />
            <meta name="twitter:image" content={seoData.image} />
            <meta name="twitter:image:alt" content={seoData.description} />

            {keywords && <meta property="keywords" content={keywords} />}

            <link rel="shortcut icon" href="/images/logo.png" />
            {/* font-family: 'Barlow Condensed' 'Barlow Semi Condensed' 'Noto Sans TC'; */}

            <link
              rel="preload"
              href="https://fonts.googleapis.com/css?family=Barlow+Condensed:600|Barlow+Semi+Condensed:200|Noto+Sans+TC:400|Noto+Sans+TC:300&display=swap"
              as="style"
              onload="this.rel='stylesheet'"
            />
            <noscript>
              {
                '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Barlow+Condensed:600|Barlow+Semi+Condensed:200|Noto+Sans+TC:400|Noto+Sans+TC:300&display=swap" />'
              }
            </noscript>
          </Helmet>

          {showNavbar && (
            <Navbar
              navbarHeight={navbarHeight}
              pathname={pathname}
              lang={this.state.lang}
              passBreakpoint={passBreakpoint || withPaddingTop}
            />
          )}

          <Content
            showNavbar={showNavbar}
            showFooter={showFooter}
            lang={this.state.lang}
          >
            {React.cloneElement(this.props.children, { lang: this.state.lang })}
          </Content>

          {showFooter && (
            <Footer
              lang={this.state.lang}
              footerHeight={footerHeight}
              mobileFooterHeight={mobileFooterHeight}
              pathname={pathname}
              isPhone={this.state.isPhone}
            />
          )}
          <ContactForm />
        </Wrapper>
      </Delighters>
    );
  }

  _normalizePath = path => {
    if (path.slice(0, 2) === "//") {
      path = path.slice(1);
    }

    if (path[path.length - 1] !== "/") {
      path += "/";
    }

    return path;
  };

  _onScroll = e => {
    const breakpoint = 600;
    let scrollTop = document.querySelector("html").scrollTop;

    if (scrollTop > breakpoint) {
      if (!this.passBreakpoint) {
        this.passBreakpoint = true;
        this.forceUpdate();
      }
    } else {
      if (this.passBreakpoint) {
        this.passBreakpoint = false;
        this.forceUpdate();
      }
    }
  };
}

const Wrapper = styled.div`
  min-height: calc(100vh);
  background-color: white;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding-top: ${props => (props.showNavbar ? navbarHeight : 0)}px;
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 500px) {
  }
`;

Layout = connect(() => ({}), ActionCreator)(Layout);

export default Layout;
